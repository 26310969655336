import React, { useEffect, useState } from 'react';

const FAQSection = () => {
  const [countersec, setCounter] = useState([]);
  const [items, setItems] = useState([]);
  const [faq, setFAQsection] = useState([]);

  useEffect(() => {
    fetch(`https://supernewecommerceseo-847916e26812.herokuapp.com/faq`)
      .then((res) => res.json())
      .then((info) => setFAQsection(info));
  }, []);

  useEffect(() => {
    fetch(`https://supernewecommerceseo-847916e26812.herokuapp.com/faq-items`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);

  useEffect(() => {
    fetch(`https://supernewecommerceseo-847916e26812.herokuapp.com/counters`)
      .then((res) => res.json())
      .then((info) => setCounter(info));
  }, []);

  return (


    <>
      <div className="vcamp-text-block-five mt-170 lg-mt-120">
        <div className="container">
          {
            faq.map(f=> <div className="row align-items-center">


            <div className="col-xxl-5 col-lg-6">
              <div className="text-wrapper">
                <div className="title-style-five">
                  <div className="upper-title">{f.faqText}</div>
                  <h2 className="title">{f.faqHeading}</h2>
                </div>
                <p className="text-lg">{f.faqDetails}</p>
              </div>
            </div>



            <div className="col-xl-5 col-lg-6 ms-auto">
              <div className="">
                <img src={f.faqImage} alt />
              </div>

            </div>
          </div>)
          }
         
        </div>
      </div>

    </>

  );
};

export default FAQSection;