import React from "react";
import Banner from "../components/Banner";

import ServicesSection from "../components/HomePage/ServicesSection";
import AboutSection from "../components/HomePage/AboutSection";
import CounterSection from "../components/HomePage/CounterSection";

import CallToAction from "../components/HomePage/CallToAction";
import TestimonialSection from "../components/HomePage/TestimonialSection";


import PricingSection from "../components/HomePage/PricingSection";
import TeamMembers from "../components/HomePage/TeamMembers";
import ExperienceSection from "../components/HomePage/ExperienceSection";
import FAQSection from "../components/HomePage/FAQSection";








const Home = () => {
 
  return (
    <div>
      <Banner></Banner>
      <AboutSection></AboutSection>
      <ServicesSection></ServicesSection>
      <FAQSection></FAQSection> 
      <PricingSection></PricingSection>
      <TestimonialSection></TestimonialSection>
      <CallToAction></CallToAction>

    </div>
  );
};

export default Home;
