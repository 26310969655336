// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBf3J2I-VX-iHXkaZc4fU7xFomYWYWHdN0",
  authDomain: "supernewecommerceseo.firebaseapp.com",
  projectId: "supernewecommerceseo",
  storageBucket: "supernewecommerceseo.appspot.com",
  messagingSenderId: "268868495755",
  appId: "1:268868495755:web:056b7c5235e04c0597e78e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;